import { useState as f, useEffect as d } from "react";
import b from "highlight.js";
const y = { plugin: { render_markdown_codehighlight: { backend: {
  info: {
    name: { other: "Code Highlight" },
    description: { other: "Highlight code blocks in Markdown content" }
  },
  config: { css_filtering: { title: { other: "Code Highlight Theme Selection" } } }
} } } }, w = { plugin: { render_markdown_codehighlight: { backend: {
  info: {
    name: { other: "代码高亮" },
    description: { other: "高亮显示 Markdown 内容中的代码块" }
  },
  config: { css_filtering: { title: { other: "代码高亮主题选择" } } }
} } } }, _ = {
  en_US: y,
  zh_CN: w
}, p = {
  "1c": {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  a11y: {
    light: () => import("./a11y-light.min-ce6d4853.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  agate: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./agate.min-f2d63036.js")
  },
  an: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./an-old-hope.min-09e6d9e4.js")
  },
  androidstudio: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./androidstudio.min-2fa97729.js")
  },
  arduino: {
    light: () => import("./arduino-light.min-a2e0c464.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  arta: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./arta.min-9c67af15.js")
  },
  ascetic: {
    light: () => import("./ascetic.min-b4cde7b3.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  atom: {
    light: () => import("./atom-one-light.min-b49deef2.js"),
    dark: () => import("./atom-one-dark.min-a9336f74.js")
  },
  brown: {
    light: () => import("./brown-paper.min-6c919433.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  codepen: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./codepen-embed.min-cf209a39.js")
  },
  color: {
    light: () => import("./color-brewer.min-654fa326.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  dark: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./dark.min-b505e08a.js")
  },
  default: {
    light: () => import("./default.min-27e1feac.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  devibeans: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./devibeans.min-285f2efb.js")
  },
  docco: {
    light: () => import("./docco.min-be6f6e56.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  far: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./far.min-9f0abf40.js")
  },
  felipec: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./felipec.min-ec559fde.js")
  },
  foundation: {
    light: () => import("./foundation.min-69fa5991.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  github: {
    light: () => import("./github.min-44a16f8f.js"),
    dark: () => import("./github-dark.min-f784f114.js")
  },
  gml: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./gml.min-ef17451c.js")
  },
  googlecode: {
    light: () => import("./googlecode.min-4fc6bd5e.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  gradient: {
    light: () => import("./gradient-light.min-4b8616ce.js"),
    dark: () => import("./gradient-dark.min-cd74939b.js")
  },
  grayscale: {
    light: () => import("./grayscale.min-605b8170.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  hybrid: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./hybrid.min-72c6e3d3.js")
  },
  idea: {
    light: () => import("./idea.min-ac08c911.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  intellij: {
    light: () => import("./intellij-light.min-8306bbb3.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  ir: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./ir-black.min-d5dc0186.js")
  },
  isbl: {
    light: () => import("./isbl-editor-light.min-22c5d266.js"),
    dark: () => import("./isbl-editor-dark.min-f91a9d94.js")
  },
  kimbie: {
    light: () => import("./kimbie-light.min-1b920cf8.js"),
    dark: () => import("./kimbie-dark.min-6037557f.js")
  },
  lightfair: {
    light: () => import("./lightfair.min-62ce6072.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  lioshi: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./lioshi.min-8efbf20d.js")
  },
  magula: {
    light: () => import("./magula.min-059d20ce.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  mono: {
    light: () => import("./mono-blue.min-c70b2513.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  monokai: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./monokai.min-6ca87247.js")
  },
  night: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./night-owl.min-529d86b5.js")
  },
  nnfx: {
    light: () => import("./nnfx-light.min-20880b39.js"),
    dark: () => import("./nnfx-dark.min-0c29fffc.js")
  },
  nord: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./nord.min-6f23b30f.js")
  },
  obsidian: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./obsidian.min-fb47ff33.js")
  },
  panda: {
    light: () => import("./panda-syntax-light.min-6e028f1c.js"),
    dark: () => import("./panda-syntax-dark.min-4fd9a6ec.js")
  },
  paraiso: {
    light: () => import("./paraiso-light.min-b00cfb23.js"),
    dark: () => import("./paraiso-dark.min-4f9693c3.js")
  },
  pojoaque: {
    light: () => import("./pojoaque.min-321cb818.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  purebasic: {
    light: () => import("./purebasic.min-59d40be2.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  qtcreator: {
    light: () => import("./qtcreator-light.min-450fd9ca.js"),
    dark: () => import("./qtcreator-dark.min-78b63047.js")
  },
  rainbow: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./rainbow.min-0e098df3.js")
  },
  routeros: {
    light: () => import("./routeros.min-9880cbac.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  school: {
    light: () => import("./school-book.min-5a8572b5.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  shades: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./shades-of-purple.min-87ad2437.js")
  },
  srcery: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./srcery.min-02cad8d5.js")
  },
  stackoverflow: {
    light: () => import("./stackoverflow-light.min-0ae7d2c8.js"),
    dark: () => import("./stackoverflow-dark.min-f8078c4a.js")
  },
  sunburst: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./sunburst.min-394f6b2f.js")
  },
  tokyo: {
    light: () => import("./tokyo-night-light.min-5e874796.js"),
    dark: () => import("./tokyo-night-dark.min-f9612f04.js")
  },
  tomorrow: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./tomorrow-night-bright.min-ede38db4.js")
  },
  vs: {
    light: () => import("./vs.min-d1bc6b60.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  vs2015: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./vs2015.min-02a9bba8.js")
  },
  xcode: {
    light: () => import("./xcode.min-6a889a84.js"),
    dark: () => import("./a11y-dark.min-7cc50c0d.js")
  },
  xt256: {
    light: () => import("./1c-light.min-ac54ac54.js"),
    dark: () => import("./xt256.min-fd130c02.js")
  }
}, v = (r) => {
  const [a, c] = f("default");
  return d(() => {
    fetch("/answer/api/v1/render/config").then((t) => t.json()).then((t) => {
      console.log("Fetched theme:", t.data.select_theme), c(t.data.select_theme);
    }).catch((t) => {
      console.error("Error fetching theme:", t);
    });
  }, []), d(() => {
    let t;
    if (r instanceof HTMLElement)
      t = r;
    else if (r && r.current instanceof HTMLElement)
      t = r.current;
    else
      return;
    const e = async (i) => {
      const l = document.querySelector('style[data-theme-style="highlight"]');
      l && l.remove();
      const o = document.createElement("style");
      o.setAttribute("data-theme-style", "highlight"), document.head.appendChild(o);
      const k = i === "dark" ? "dark" : "light", u = await (p[a] || p.default)[k]();
      o.innerHTML = u.default, t.querySelectorAll("pre code").forEach((m) => {
        b.highlightElement(m), m.style.backgroundColor = "transparent", m.style.padding = "0";
      });
    }, s = document.documentElement.getAttribute("data-bs-theme") || "light";
    e(s);
    const n = new MutationObserver(() => {
      const i = document.documentElement.getAttribute("data-bs-theme") || "light";
      console.log("Detected code content change, reapplying syntax highlighting, current theme:", i), e(i);
    });
    n.observe(t, {
      childList: !0,
      // Observe changes to child elements
      subtree: !0
      // Observe the entire subtree
    });
    const h = new MutationObserver(() => {
      const i = document.documentElement.getAttribute("data-bs-theme") || "light";
      console.log("Detected theme change:", i), e(i);
    });
    return h.observe(document.documentElement, {
      attributes: !0,
      attributeFilter: ["data-bs-theme"]
    }), () => {
      n.disconnect(), h.disconnect();
    };
  }, [r, a]), null;
}, g = {
  slug_name: "render_markdown_codehighlight",
  type: "render",
  version: "0.0.4",
  author: "Chen Jiaji, Zhu Xuanlyu",
  link: "https://github.com/apache/incubator-answer-plugins/tree/main/render-markdown-codehighlight"
}, C = {
  info: {
    slug_name: g.slug_name,
    type: g.type
  },
  i18nConfig: _,
  hooks: {
    useRender: [v]
  }
};
export {
  C as default
};
